import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ShowGuesser} from "@api-platform/admin";
import {
    AutocompleteInput,
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField,
    TextInput,
    SelectInput,
    SelectField,
    TabbedShowLayout,
    Show,
    ReferenceManyField,
    EmailField,
    EditButton,
} from 'react-admin';

const typeChampChoices = [
    { id: 'email', name: 'Adresse email' },
    { id: 'telephone', name: 'Numéro de téléphone' },
    { id: 'lien', name: 'Lien URL' },
    { id: 'texte_court', name: 'Texte court' },
    { id: 'texte_long', name: 'Texte long' },
];

const filters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <TextInput source={"nom"} />,
    <SelectInput source={"type"} choices={typeChampChoices} />,
];

export const ChampPersonnaliseList = props => (
    <List {...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <ReferenceField reference="communaute" source="communaute" label="Communaute">
                <TextField source="titre" />
            </ReferenceField>
            <FieldGuesser source={"nom"} />
            <SelectField source={"type"} choices={typeChampChoices} />
            <FieldGuesser source={"description"} />
        </Datagrid>
    </List>
);

export const ChampPersonnaliseShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Détails">
                <ReferenceField reference="communautes" source="communaute">
                    <TextField source="titre" />
                </ReferenceField>
                <TextField source="nom" />
                <SelectField source="type" choices={typeChampChoices} />
                <TextField source="description" />
            </TabbedShowLayout.Tab>
            
            <TabbedShowLayout.Tab label="Valeurs">
                <ReferenceManyField
                    reference="valeur_champ_personnalises"
                    target="champPersonnalise"
                    label="Valeurs"
                >
                    <Datagrid rowClick="edit">
                        <ReferenceField source="utilisateur" reference="utilisateurs">
                            <TextField source="email" />
                        </ReferenceField>
                        <TextField source="valeur" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
);

export const ChampPersonnaliseCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <InputGuesser source={"nom"} required />
        <SelectInput source={"type"} choices={typeChampChoices} required />
        <InputGuesser source={"description"} label={"Description du champ"}/>
    </CreateGuesser>
);

export const ChampPersonnaliseEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"} disabled />
        </ReferenceInput>
        <InputGuesser source={"nom"} required />
        <SelectInput source={"type"} choices={typeChampChoices} required />
        <InputGuesser source={"description"} />
    </EditGuesser>
);