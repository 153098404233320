// Valeur par défaut pour le développement local
const DEFAULT_API_URL = 'http://localhost:8200/api';

// Récupérer l'URL de l'API
// 1. Essayer window._env_ (variables d'environnement chargées dynamiquement depuis env.js)
// 2. Sinon, essayer process.env (variables d'environnement au moment du build)
// 3. Sinon, utiliser la valeur par défaut
const getApiUrl = () => {
  // Vérifier si window._env_ existe et contient REACT_APP_API_URL
  if (typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_API_URL) {
    console.log('Using window._env_.REACT_APP_API_URL:', window._env_.REACT_APP_API_URL);
    return window._env_.REACT_APP_API_URL;
  }
  
  // Sinon, utiliser process.env (pour le développement local ou au moment du build)
  if (process.env.REACT_APP_API_URL) {
    console.log('Using process.env.REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
    return process.env.REACT_APP_API_URL;
  }
  
  // Valeur par défaut
  console.log('Using DEFAULT_API_URL:', DEFAULT_API_URL);
  return DEFAULT_API_URL;
};

export const ENTRYPOINT = getApiUrl();

// Pour débogage
console.log('API URL:', ENTRYPOINT);